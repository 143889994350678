export type Order = "asc" | "desc";

export const TickConstants = {
	Signed: ".",
	Mined: "✓",
	NotSigned: "✘",
	NotFound: "~",
};

export function getCeloLinkForSigner(url: string, address: string): string {
	return url + "address/" + address;
}

export function getCeloLinkForValidator(url: string, address: string): string {
	return url + "address/" + address + "/celo";
}

export function getCometBFTLinkForValidator(
	url: string,
	address: string,
): string {
	return url + address;
}

export function getFavouriteForSigner(address: string): boolean {
	const favourite = localStorage.getItem(address);
	if (favourite === null) {
		return false;
	} else if (favourite === "true") {
		return true;
	} else {
		return false;
	}
}

export function getPageTitleBasedOnNetwork(network: string): string {
	if (network.includes(CeloMainnetKey)) {
		return CeloNetworkDisplayKey;
	} else if (network.includes(CeloBaklavaKey)) {
		return CeloBaklavaNetworkDisplayKey;
	} else if (network.includes(NamadaNetworkKey)) {
		return NamadaNetworkDisplayKey;
	} else if (network.includes(NymNetworkKey)) {
		return NymNetworkDisplayKey;
	}
}

// constants
export const CeloMainnetKey = "Mainnet";
export const CeloBaklavaKey = "Baklava";

// constants
export const CeloNetworkKey = "celo";
export const NamadaNetworkKey = "namada";
export const NymNetworkKey = "nym";

export const CeloNetworkDisplayKey = "Celo";
export const CeloBaklavaNetworkDisplayKey = "Celo Baklava";
export const NamadaNetworkDisplayKey = "Namada";
export const NymNetworkDisplayKey = "Nym";

export const SignedBlockNetworks: string[] = [CeloMainnetKey, CeloBaklavaKey];
export const AttestationNetworks: string[] = [CeloMainnetKey, CeloBaklavaKey];
export const RpcNetworks: string[] = [CeloMainnetKey, CeloBaklavaKey];
export const MetricsNetworks: string[] = [CeloMainnetKey, CeloBaklavaKey];
export const SubscriptionNetworks: string[] = [CeloMainnetKey, CeloBaklavaKey];
export const NamadaSignedBlockNetworks: string[] = [NamadaNetworkKey];
export const NymSignedBlockNetworks: string[] = [NymNetworkKey];
export type LocaleKey = "en";

export const CurrentNetwork = "Current_Network";
export enum CeloRiveraResponse {
	Success = 1,
	PartialSuccess,
	Error,
	Warning,
}

export enum ExportTypes {
	json = 1,
	csv,
}

export type ResponseData = {
	state: CeloRiveraResponse;
	message: string;
};

export type RiveraResponse<T> = {
	state: CeloRiveraResponse;
	data?: T;
	message?: string;
};

export type ExportData = {
	type: ExportTypes;
	from?: Date;
	to?: Date;
	entity: string;
	fileName: string;
};
